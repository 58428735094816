import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  INotification,
  INotificationNormalizedResponse,
  INotificationQueryParam,
  TNotificationRawResponse,
  ICreateNotification,
} from './typings';
import { QUERIES } from './constants';

export const notificationAdapter = createEntityAdapter({
  selectId: (item: INotification) => item.id,
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});

export const notificationSelector = notificationAdapter.getSelectors();

export const notificationApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'notification-api',
    baseQuery,
    tagTypes: [QUERIES.notifications],
    endpoints: (builder) => ({
      getNotifications: builder.query<INotificationNormalizedResponse, INotificationQueryParam>({
        query: ({ page, pageSize, user_id }) => ({
          url: 'public/v1/notification/notification',
          params: { page, pageSize, user_id },
        }),
        providesTags: [QUERIES.notifications],
        transformResponse: (response: TNotificationRawResponse) => ({
          notifications: notificationAdapter.setAll(
            notificationAdapter.getInitialState(),
            response.notifications,
          ),
          total_pages: response.total_pages,
          total_records: response.total_records,
          unread_notifications: response.unread_notifications,
        }),
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          return !isSamePage;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs?.user_id}`,
        merge: (currentState, incomingState) => {
          notificationAdapter.addMany(
            currentState.notifications,
            notificationSelector.selectAll(incomingState.notifications),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
          currentState.unread_notifications = incomingState.unread_notifications;
        },
      }),
      getNotificationById: builder.query<INotification, string>({
        query: (id) => ({
          url: `public/v1/notification/notification/${id}`,
        }),
        providesTags: (result, error, id) => [{ type: QUERIES.notifications, id }],
      }),
      createNotification: builder.mutation<INotification, ICreateNotification>({
        query: (notificationData) => ({
          url: 'public/v1/notification/notification',
          method: 'POST',
          body: notificationData,
        }),
        invalidatesTags: [QUERIES.notifications],
      }),
      updateNotification: builder.mutation<
        INotification,
        INotification & { queryParams: INotificationQueryParam }
      >({
        query: ({ id, ...rest }) => ({
          url: `public/v1/forum/message/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: [QUERIES.notifications],
        async onQueryStarted({ id, ...rest }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData('getNotifications', rest.queryParams, (draft) => {
              const notification = notificationSelector.selectById(draft.notifications, id);
              if (!notification) return;

              notificationAdapter.updateOne(draft.notifications, {
                id,
                changes: {
                  ...notification,
                  ...rest,
                },
              });
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      }),
      deleteNotification: builder.mutation<{ success: boolean }, string>({
        query: (id) => ({
          url: `public/v1/notification/notification/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, id) => [{ type: QUERIES.notifications, id }],
      }),
    }),
  });

  return api;
};
