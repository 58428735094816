import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  IChallenge,
  IChallengeNormalizedResponse,
  IChallengeQueryParam,
  TChallengeRawResponse,
  ICreateChallenge,
  IUpdateChallenge,
  IChallengeRequirement,
  IChallengeSet,
} from './typings';
import { QUERIES } from './constants';

export const challengeAdapter = createEntityAdapter({
  selectId: (item: IChallenge) => item?.id,
  // sortComparer: (a, b) => b?.created_at.localeCompare(a?.created_at),
  sortComparer: (a, b) => {
    if (!a?.created_at || !b?.created_at) return 0; // Handle null or undefined created_at
    return b?.created_at.localeCompare(a?.created_at);
  },
});

export const challengeSelector = challengeAdapter.getSelectors();

export const challengeApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'challenge-api',
    baseQuery,
    tagTypes: [QUERIES.challenges],
    endpoints: (builder) => ({
      getChallenges: builder.query<IChallengeNormalizedResponse, IChallengeQueryParam>({
        query: ({ page, pageSize, creator_id, status }) => ({
          url: 'public/v1/challenge',
          params: {
            page,
            pageSize,
            creator_id,
            status: status === 'archived' ? 'active' : status,
          },
        }),
        providesTags: [QUERIES.challenges],
        transformResponse: (response: TChallengeRawResponse) => {
          const initialState = challengeAdapter.getInitialState();
          return {
            challenges: challengeAdapter.setAll(initialState, response.challenges),
            total_pages: response.total_pages,
            total_records: response.total_records,
          };
        },
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          const isSameStatus = currentArg?.status === previousArg?.status;
          return !isSamePage || !isSameStatus;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs?.creator_id}-${queryArgs?.status}`,
        merge: (currentState, incomingState) => {
          challengeAdapter.addMany(
            currentState.challenges,
            challengeSelector.selectAll(incomingState.challenges),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
        },
      }),
      getChallengeById: builder.query<IChallengeSet, string>({
        query: (id) => ({
          url: `public/v1/challenge/${id}`,
        }),
        providesTags: (result, error, id) => [{ type: QUERIES.challenges, id }],
      }),
      createChallenge: builder.mutation<IChallenge, ICreateChallenge>({
        query: (challengeData) => ({
          url: 'public/v1/challenge',
          method: 'POST',
          body: challengeData,
        }),
        invalidatesTags: [QUERIES.challenges],
      }),
      updateChallenge: builder.mutation<
        IChallenge,
        IUpdateChallenge & { queryParams: IChallengeQueryParam }
      >({
        query: ({ id, ...rest }) => ({
          url: `public/v1/challenge/${id}`,
          method: 'PUT',
          body: rest,
        }),
        invalidatesTags: [QUERIES.challenges],
        async onQueryStarted({ id, ...rest }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData('getChallenges', rest.queryParams, (draft) => {
              const challenge = challengeSelector.selectById(draft.challenges, id);
              if (!challenge) return;

              challengeAdapter.updateOne(draft.challenges, {
                id,
                changes: {
                  ...challenge,
                  ...rest,
                },
              });
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      }),
      deleteChallenge: builder.mutation<
        { success: boolean },
        { id: string; queryParams: IChallengeQueryParam }
      >({
        query: ({ id }) => ({
          url: `public/v1/challenge/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, param) => [{ type: QUERIES.challenges, id: param.id }],
        async onQueryStarted({ id, queryParams }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData('getChallenges', queryParams, (draft) => {
              challengeAdapter.removeOne(draft.challenges, id);
            }),
          );
          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      }),
      getChallengeBadgeRequirementsById: builder.query<
        IChallengeRequirement,
        { id: string; fan_id: string }
      >({
        query: ({ id, fan_id }) => ({
          url: `public/v1/challenge_required_badges/${id}`,
          params: { fan_id },
        }),
        providesTags: (result, error, param) => [{ type: QUERIES.challenges, id: param.id }],
      }),
    }),
  });

  return api;
};
