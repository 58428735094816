'use client';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Lottie from 'lottie-react';
import { CircleCheckBig, CircleX } from 'lucide-react';
import animationData from './congrat-confetti.json';
import './modal.css';

interface CongratulationsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onViewBadge: () => void;
  challengeName?: string;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({
  isOpen,
  onClose,
  onViewBadge,
  challengeName = 'Challenge!',
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Only render the portal on the client side
  if (!isMounted) {
    return null;
  }
  const modalContent = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 top-0 left-0 bottom-0 right-0 z-50 flex items-center justify-center p-1 lg:p-12 bg-black/90 modal-overlay cursor-none"
          style={{ zIndex: 1000 }}>
          {/* Modal Container */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: 'spring', damping: 20, stiffness: 300 }}
            onClick={(e) => e.stopPropagation()}
            className="relative h-full w-full lg:w-1/2 animated-gradient lg:rounded-2xl modal-content">
            {/* Glass Effect Overlay */}
            <CircleX
              className="absolute z-50 top-4 right-4 w-6 h-6 text-color hover:text-white/90 cursor-pointer opacity-50"
              onClick={() => onClose()}
            />
            {/* <div className="absolute inset-0 " /> */}

            {/* Lottie Animation Container */}
            <div className="absolute top-20">
              <Lottie animationData={animationData} loop={true} className="w-full h-full" />
            </div>
            <div className="absolute top-0">
              <Lottie animationData={animationData} loop={true} className="w-full h-full" />
            </div>

            {/* Content Container */}
            <div className="relative z-10 flex flex-col items-center justify-center w-full h-full p-8">
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-sm space-x-2 text-center text-color mb-4 drop-shadow-lg flex items-center">
                <CircleCheckBig className="w-4 h-4" />
                <span>challenge completed</span>
                <div className="absolute">
                  <Lottie animationData={animationData} loop={true} className="w-full h-full" />
                </div>
              </motion.p>
              <motion.h2
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-4xl md:text-5xl font-bold text-center text-white mb-4 drop-shadow-lg">
                Congratulations!
              </motion.h2>
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-[14px] max-w-[500px] space-x-2 text-center text-color ">
                <span>
                  Congratulations on completing the{' '}
                  <span className="text-color font-bold">{challengeName || ''} Challenge!</span>{' '}
                  You’ve earned it—claim your prize badge now and show off your achievement!
                </span>
              </motion.p>

              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                onClick={() => {
                  onViewBadge();
                  onClose();
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-8 px-8 py-3 bg-white text-[#FF600D] rounded-full hover:bg-white/90 transition-colors font-semibold shadow-lg backdrop-blur-sm">
                Claim Prize Badge
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Only create portal on client side
  if (typeof window !== 'undefined') {
    const { createPortal } = require('react-dom');
    return createPortal(modalContent, document.body);
  }

  return null;
};

export default CongratulationsModal;
